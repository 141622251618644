import React from 'react';

const FinancialSection = () => {
  return (
    <section id="Financial">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/financialHero_dsk.jpg"
                alt="WoodmenLife Tower at sunset"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/financialHero_mbl.jpg"
                alt="WoodmenLife Tower at sunset"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
           <h2 style={{ margin: '0 0 0 0' }} className="navy size24"><b>WoodmenLife Is</b></h2>
            <h1 style={{ margin: '0 0 30px 0' }} className="Oswald navy">Financially Strong</h1>
            
            <p>  
            Our conservative, long-term investment approach is the foundation for the financial stability of our organization. In an industry based on long-term success, customers and partners want to do business with a company they know will perform. WoodmenLife is an organization that has been strong for more than a century by looking ahead and doing what's right.
            </p>
            <p>
            Our ongoing strategic objective is to grow insurance liabilities and increase capital tied to those liabilities. We're well capitalized for risks in the current business cycle and for the future. In short, we're in an excellent position and will continue to achieve our business goals.
            </p>
            <p>
            We follow an investment philosophy that allows us to grow and remain financially strong. More than two-thirds of our invested assets are in bonds, due to their stability and consistent returns. A large portion of our bond portfolio contains investment grade corporate bonds.
            </p>
            <p>
            In addition, real estate accounted for under 1% of WoodmenLife's invested assets in 2023. These holdings consist of property that's owned by, and either leased to others or occupied by, WoodmenLife. At the end of 2023, 21% of our assets were in mortgage loans. These loans are secured by commercial real estate, including industrial, retail and office properties.
            </p>
            <p>
            Solid decision-making has made WoodmenLife an $11.3 billion<span className="size12"><sup>3</sup></span> company with a surplus of $1.7 billion that allows us to invest while maintaining enough money to cover our responsibilities. We have a 17.5% asset-to-liabilities ratio.
            </p>
            <p>
            WoodmenLife's outstanding financial performance included a total income of $1.1 billion in 2023. We also had certificate reserves of $8 billion. Plus, our net gain before refunds was $34 million.
            </p>
            

            <div
              style={{
                float: 'right',
                width: '250px',
                margin: '0px 0px 20px 20px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
              >
                <img
                  src="/brochure/8954/images/amBest.png"
                  alt="AM Best Financial Strength Rating A+ graphic"
                  width="250"
                  style={{ margin: '0' }}
                />
              </div>

            <p>
            Our conservative approach and financial strength are why the independent rating agency AM Best awarded us an A+ Superior rating<span className="size12"><sup>4</sup></span>, the second highest of 13. We've received the A+ Superior rating for 48 consecutive years.
            </p>

              <div
                style={{
                  width: '100%',
                  margin: '0px 0px 30px 0px',
                  backgroundColor: '#ffffff',
                }}
                className="hideLg text-center"
              >
                  <img
                    src="/brochure/8954/images/amBest.png"
                    alt="AM Best Financial Strength Rating A+ graphic"
                    style={{ margin: '0' }}
                    width="200"
                  />
              </div>

            

          </div>
          <div className="col-lg-4 mx-auto" style={{ backgroundColor: '#f4f1ed' }}>
           
            <div className="size18" style={{ margin: '20px 0 10px 0' }}>
              <span className="Oswald">Distribution of Assets</span>
              <div
                style={{
                  width: '100%',
                  margin: '5px 0px 20px 0px',
                }}
                className="centerText"
              >
                <img
                  src="/brochure/8954/images/fin-dist-graph-2024.svg"
                  alt="Pie chart illustrating WoodmenLife's Distribution of Assets"
                  className="img-responsive"
                />
              </div>
            </div>
              
            <div className="container">
              <div className="row">
                <div className="col-lg-10 mx-auto lineSplitterTight"></div>
              </div>
            </div>

            <div className="size18" style={{ margin: '20px 0 10px 0' }}>
            <span className="Oswald">Assets</span> 10-Year History
              <div
                style={{
                  width: '100%',
                  margin: '0px 0px 20px 0px',
                }}
              >
                <img
                  src="/brochure/8954/images/fin-assets-graph-2024.svg"
                  alt="Pie chart illustrating WoodmenLife's Distribution of Assets"
                  className="img-responsive"
                />
              </div>

              <div style={{ margin: '5px 0 20px 0' }}>
                <p className="size10">
                  <b>DOLLARS IN ASSETS (BILLIONS)</b><br/>
                  Liabilities of $9.6 billion; as of Dec. 31, 2023.
                </p>
              </div>

            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-10 mx-auto lineSplitterTight"></div>
              </div>
            </div>

            <div className="size18" style={{ margin: '30px 0 10px 0' }}>
            <span className="Oswald">Surplus</span> 10-Year History
              <div
                style={{
                  width: '100%',
                  margin: '0px 0px 20px 0px',
                }}
              >
                <img
                  src="/brochure/8954/images/fin-surplus-graph-2024.svg"
                  alt="Pie chart illustrating WoodmenLife's Distribution of Assets"
                  className="img-responsive"
                />
              </div>

              <div style={{ margin: '5px 0 20px 0' }}>
                <p className="size10">
                  <b>(BILLIONS)</b>
                </p>
              </div>
            </div>


            

          </div>
        </div>
      </div>

     <br/><br/>     

    

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '20px' }} className="size10">
            3. Liabilities of $9.6 billion as of Dec. 31, 2023.<br/>
            4. WoodmenLife is rated A+ Superior by AM Best for our financial strength and operating performance in 2023. 
            For the latest Best's Credit Rating, access www.ambest.com
            </div>

            
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
          
            <div className="size14" style={{ margin: '40px 0 0 0' }}>
              <b>In Partnership, We Succeed</b>
            </div>
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Benefits"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default FinancialSection;
