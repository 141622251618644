import React from 'react';

const DeniseMcCauleySection = () => {
  return (
    <section id="Denise">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/130StrongHero_dsk.png"
                alt="130 Years Strong graphic"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/130StrongHero_mbl.png"
                alt="130 Years Strong graphic"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
          
          <h1 style={{ margin: '0 0 5px 0' }} className="Oswald navy">Denise M. McCauley</h1>
          <div style={{ margin: '0 0 30px 0' }} className="navy size20"><b>President &#38; CEO</b></div>
           
            
            <div
              style={{
                float: 'right',
                width: '300px',
                margin: '10px 0px 30px 30px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/8954/images/DeniseMcCauley.jpg"
                alt="Patrick L. Dees, President &#38; CEO"
                width="300"
              />
            </div>
            
            <div
              style={{
                float: 'right',
                width: '150px',
                margin: '10px 0px 20px 20px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/8954/images/DeniseMcCauley.jpg"
                alt="Patrick L. Dees, President &#38; CEO"
                width="150"
              />
            </div>

            <p>
            On behalf of all our associates, we welcome you to WoodmenLife and would like to take this opportunity to tell you about who we are and what we do.
            </p>
            <p>
            WoodmenLife was founded in 1890 with a simple two-part purpose: to protect America's families from financial hardship and to provide opportunities to help others in the community. Today, our mission mirrors that original purpose and drives us to unite hardworking Americans to secure their financial future while strengthening our communities and country.
            </p>
            <p>
            As a not-for-profit life insurance organization, we reinvest in the communities where our members work and live. We are financially sound and have been gaining their trust for more than 130 years by taking a long-term approach to maintaining financial stability.
            </p>
            <p>
            We make decisions in the best interest of our members' lifetime security, because their confidence in us matters.
            </p>
            <p>
            We are certain that teaming up with WoodmenLife will give your organization a competitive edge in the market. Our consistent financial stability, attractive product offerings and unwavering commitment to our members make us the ideal partner. We are determined to provide you with the same level of support and dedication that we offer our members.
            </p>
              
            <p>
              Sincerely,<br/>
              <img
                src="/brochure/8954/images/DeniseSig.png"
                alt="Denise M. McCauley signature"
                style={{ margin: '10px 0 10px 0', width: '200px'}}
              /><br/>
              Denise M. McCauley<br/>
              President &#38; CEO
            </p>

          </div>
        </div>
      </div>

      


      <div className="container text-center">
        <div>
            

            <div className="size14" style={{ margin: '40px 0 0 0' }}>
              <b>In Partnership, We Succeed</b>
            </div>

            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Kyle"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default DeniseMcCauleySection;
