import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import BenefitsSection from '../../../components/Brochure/8954/BenefitsSection';
import DeniseMcCauleySection from '../../../components/Brochure/8954/DeniseMcCauleySection';
import FinancialSection from '../../../components/Brochure/8954/FinancialSection';
import FooterSection from '../../../components/Brochure/8954/FooterSection';
import HeroSection from '../../../components/Brochure/8954/HeroSection';
import HomeSection from '../../../components/Brochure/8954/HomeSection';
import KyleMcMahanSection from '../../../components/Brochure/8954/KyleMcMahanSection';
import LanceLarsenSection from '../../../components/Brochure/8954/LanceLarsenSection';
import NavSection from '../../../components/Brochure/8954/NavSection';

import TrustedSection from '../../../components/Brochure/8954/TrustedSection';
import useScript from '../../../hooks/useScript';

const Brochure8954 = () => {

  useScript(withPrefix('/brochure/js/jquery.min.js'));
  useScript(withPrefix('/brochure/js/bootstrap.bundle.min.js'));
  useScript(withPrefix('/brochure/js/jquery.easing.min.js'));
  useScript(withPrefix('/js/scrolling-nav.js'));
  useScript(withPrefix('/js/zoom.js'));

  return (
    <>
      <Helmet>
        <html className="brochure" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>
          Form 8954D R-7/23 - WoodmenLife - In Partnership, We Succeed
        </title>

        <link href="/brochure/css/bootstrap.min.css" rel="stylesheet" />

        <link href="/brochure/css/styles.css?rev=20210527" rel="stylesheet" />
        <link href="/brochure/css/overrides.css" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/8f3009470a.js"></script>

        <link
          href="https://fonts.googleapis.com/css?family=Oswald:700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,800,800i"
          rel="stylesheet"
        />
      </Helmet>
      <body id="page-top">
        <NavSection />
        <HeroSection />
        <DeniseMcCauleySection />
        <KyleMcMahanSection />
        <TrustedSection />
        <FinancialSection />
        <BenefitsSection />
        <HomeSection />
        <LanceLarsenSection />
        <FooterSection />
      </body>
    </>
  );
};

export default Brochure8954;
