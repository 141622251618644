import React from 'react';

const HeroSection = () => {
  return (
    <div className="heroBkg_8954">
      <header className="white">
        <div className="container text-center">
          <div>
            
            
            <div style={{ margin: '200px 0 0 0' }} className="hideSm">
              <img src="images/succeed_hdl.png" alt="" />
            </div>

            <div style={{ margin: '200px 0 0 0' }} className="hideLg">
              <img
                src="images/succeed_hdl.png"
                style={{ width: '350px' }}
                alt=""
              />
            </div>

            <div
              className="arrow bounce whiteArrow"
              style={{ marginTop: '125px' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Denise"
              >
                &nbsp;
              </a>
            </div>
            
          </div>
        </div>
      </header>

      <div className="top-left darkBkg white size10">
        <b>Form 8954D R-8/24</b>
      </div>
    </div>
  );
};

export default HeroSection;
