import React from 'react';

interface NavSectionProps {
  hasRep?: boolean;
}

const NavSection = ({ hasRep }: NavSectionProps) => {
  return (
    <nav className="navbar navbar-custom fixed-top" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img
            src="/brochure/8954/images/logo.png"
            style={{ width: '200px' }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto" style={{ textAlign: 'center' }}>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Denise">
                Denise M. McCauley
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Kyle">
                Kyle McMahan
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Trusted">
                A Trusted Organization
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Financial">
                Financially Strong
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Benefits">
                Exclusive Benefits
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link js-scroll-trigger" href="#Home">
                We Call Home
              </a>
            </li>
              <li className="nav-item text-center">
                <a className="nav-link js-scroll-trigger" href="#Connect">
                  Connect with WoodmenLife
                </a>
              </li>
            <li className="nav-item">&nbsp;</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavSection;
