import React from 'react';

const TrustedSection = () => {
  return (
    <section id="Trusted">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/trustedHero_dsk.jpg"
                alt="WoodmenLife Representatives build relationships with their clients."
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/trustedHero_mbl.jpg"
                alt="WoodmenLife Representatives build relationships with their clients."
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 style={{ margin: '0 0 0 0' }} className="navy size24"><b>WoodmenLife &mdash;</b></h2>
            <h1 style={{ margin: '0 0 30px 0' }} className="Oswald navy">A Trusted Organization</h1>
            
            <p>
            When you do business with WoodmenLife®, you're partnering with a life insurance company that has been around for more than 130 years. We're committed to offering life insurance and retirement products to meet the unique needs of families and to caring about every one of our members<span className="size12"><sup>1</sup></span>.
            </p>
            <p>
            WoodmenLife is not your typical insurance company. We've been helping to protect the financial futures of families, making a difference in hometowns across America and honoring our country since 1890. As a not-for-profit life insurance company, we put money back into the community. We're here when our members need us most.
            </p>
            <p>
            WoodmenLife also made Best's Review Magazine's Standing the Test of Time list in 2023. This recognition honors life and health insurers that have maintained a Best's Credit Rating of A or higher for 50 years or more. Additionally, Forbes also listed WoodmenLife as one of the 2024 World's Best Life Insurance Companies and the 2023 America's Best Life Insurance Companies.
            </p>
            <p>
            Our members think highly of us, too. In a survey<span className="size12"><sup>2</sup></span> conducted by LIMRA, 98% of WoodmenLife members are pleased with the service they receive from us.
            </p>
            <p>
            We're a trusted provider of life insurance and retirement products that provide solutions for the unique needs of families, at every stage of life. We're here when people need us, and we'll be here in the future.
            </p>            
            
             

            
            <div
                style={{
                  width: '100%',
                  margin: '30px 0px 20px 0px',
                  backgroundColor: '#ffffff',
                }}
                className="text-center"
              >
                  <img
                    src="/brochure/8954/images/trustedLogos.png"
                    alt="AM Best, Forbes 2023, Forbes 2024 logos"
                    style={{ margin: '0' }}
                    width="100%"
                  />
              </div>  

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">
            <div style={{ marginTop: '20px' }} className="size10">
              1. An individual becomes a member by joining our shared commitment to family, community and country, and by purchasing a WoodmenLife product.<br/>
              2. Source: 2023 LIMRA Customer Assurance Program survey.
            </div>

            
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div>
            

            <div className="size14" style={{ margin: '40px 0 0 0' }}>
              <b>In Partnership, We Succeed</b>
            </div>

            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Financial"
              >
                &nbsp;
              </a>
            </div>

        </div>
      </div>

    </section>
  );
};

export default TrustedSection;
