import React from 'react';

const FooterSection = () => {
  return (
      
      <footer className="py-5 bg-navy">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 mx-auto white"
            style={{ marginTop: '0px', textAlign: 'center' }}
          >
            <a className="js-scroll-trigger" href="#page-top">
              <img
                src="images/logo.png"
                style={{ width: '200px', marginBottom: '10px' }}
                alt="woodmen logo"
              />
            </a>
            <br />

            <p className="m-0 text-center white size12">
              Woodmen of the World Insurance Society
              <br />
              Home Office: Omaha, Nebraska
              <br />
              <br />
              <span className="lightBlueLink">
                <a href="/" target="_blank">
                  woodmenlife.org
                </a>
              </span>
            </p>
            
          </div>
        </div>
      </div>
    </footer>
    
      
  );
};

export default FooterSection;
