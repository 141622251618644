import React from 'react';

const LanceLarsenSection = () => {
  return (


    <section id="Connect" className="bg-gray">
      <div className="container bg-gray">
        <div className="row">
          <div className="col-lg-3 hideSm">&nbsp;</div>
          <div className="col-lg-6 col-xs-8 mx-auto white">
            <h1
              className="Oswald white"
              style={{ marginBottom: '40px', textAlign: 'center' }}
            >
              Connect with WoodmenLife
            </h1>

           
                <div className="RepImgContainer hideSm">
                    <img
                        src="/brochure/8954/images/LanceLarsen.jpg"
                        alt="Vice President of Additional Distribution"
                        width="300"
                    />
                </div>

                <div className="RepImgContainer hideLg" style={{ textAlign: 'center', margin: '0 0px 30px 0' }} >
                  <img
                        src="/brochure/8954/images/LanceLarsen.jpg"
                        alt="Vice President of Additional Distribution"
                        width="300"
                    />
                </div>
             
            

            <div className="white mobiCenter">
              <b>Lance R. Larsen, ChFC, CLU, LLIF</b>
              <br />
              <i>Vice President, Additional Distribution</i>
              <br />
              <br />
              (402) 378-7741<br />

              <span className="lightBlueLink">
                <a href={`mailto:LLarsen@woodmenlife.org`}>
                  <b className="WLblue">LLarsen@woodmenlife.org</b>
                </a>
                <br />
              </span>
                
            </div>
          </div>
          <div className="col-lg-3 hideSm">&nbsp;</div>
        </div>
      </div>
    </section>
 

 );
};

export default LanceLarsenSection;