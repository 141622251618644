import React from 'react';

const KyleMcMahanSection = () => {
  return (
    <section id="Kyle">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/fraternalHero_dsk.jpg"
                alt="Giving Together volunteer event with high school boys"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/fraternalHero_mbl.jpg"
                alt="Giving Together volunteer event with high school boys"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">


            <h1 style={{ margin: '0 0 5px 0' }} className="Oswald navy">Kyle McMahan</h1>
            <div style={{ margin: '0 0 30px 0' }} className="navy size20"><b>Executive Vice President &#38; Chief Marketing Officer</b></div>


            <div
              style={{
                float: 'right',
                width: '300px',
                margin: '10px 0px 30px 30px',
                backgroundColor: '#ffffff',
              }}
              className="hideSm"
            >
              <img
                src="/brochure/8954/images/KyleMcMahan.jpg"
                alt="Kyle McMahan, Executive Vice President &#38; Chief Marketing Officer"
                width="300"
              />
            </div>

            <div
              style={{
                float: 'right',
                width: '150px',
                margin: '10px 0px 20px 20px',
                backgroundColor: '#ffffff',
              }}
              className="hideLg"
            >
              <img
                src="/brochure/8954/images/KyleMcMahan.jpg"
                alt="Kyle McMahan, Executive Vice President &#38; Chief Marketing Officer"
                width="150"
              />
            </div>

            <p>
              WoodmenLife would like to thank you for taking the time to learn more about our organization and the ways we contribute to the communities we serve. As a Fraternal Benefit Society, we offer a unique combination of membership, insurance and volunteerism.
            </p>
            <p>
              Because we are more than life insurance, your customers will have access to opportunities they would not otherwise have.
            </p>
            <p>
              As you will see in the pages that follow, our unique member benefits<sup>*</sup> are what set us apart from other providers, giving your customers the protection of a life insurance product and the opportunity to join the WoodmenLife family. They also will be able to give back to their communities and take advantage of the member benefits available to them.
            </p>
            <p>
              We are dedicated to helping our members share our commitment to supporting family, community and country, and we hope you will join us in that mission.
            </p>

            <p>
              Sincerely,<br />
              <img
                src="/brochure/8954/images/kyleSig.png"
                alt="Kyle McMahan signature"
                style={{ margin: '10px 0 10px 0', width: '200px' }}
              /><br />
              Kyle McMahan<br />
              Executive Vice President &#38; Chief Marketing Officer
            </p>




          </div>
        </div>
      </div>


      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xs-12 mx-auto">

            <div style={{ marginTop: '20px' }} className="size10">
              <sup>*</sup>WoodmenLife Benefits are available to members. An individual becomes a member by joining our shared commitment to family, community and country, and by purchasing a WoodmenLife product. These benefits are not contractual, are subject to change and have specific eligibility requirements, such as length of membership, number of qualifying members in household and/or qualifying event.
            </div>
          </div>
        </div>
      </div>




      <div className="container text-center">
        <div>

          <div className="size14" style={{ margin: '40px 0 0 0' }}>
            <b>In Partnership, We Succeed</b>
          </div>

          <div
            className="arrow bounce blueArrow"
            style={{ margin: '40px 0 150px 0' }}
          >
            <a
              className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
              href="#Trusted"
            >
              &nbsp;
            </a>
          </div>

        </div>
      </div>


    </section>
  );
};

export default KyleMcMahanSection;
