import React from 'react';

interface HomeSectionProps {
  hasRep?: boolean;
}

const HomeSection = ({ hasRep }: HomeSectionProps) => {
  return (
    <section id="Home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="hideSm">
              <img
                src="/brochure/8954/images/homeHero_dsk.jpg"
                alt="Gary Sinise Foundation volunteers"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>

            <div className="hideLg">
              <img
                src="/brochure/8954/images/homeHero_mbl.jpg"
                alt="Gary Sinise Foundation volunteers"
                className="img-responsive"
                style={{ margin: '0 0 20px 0' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2 style={{ margin: '0 0 0 0' }} className="navy"><b>Giving Back in the Communities</b></h2>
            <h1 style={{ margin: '0 0 30px 0' }} className="Oswald navy">We Call Home</h1>
              
              

              <div
                style={{
                  float: 'right',
                  width: '400px',
                  margin: '0 0 20px 20px',
                  backgroundColor: '#ffffff',
                }}
                className="hideSm"
              >
                <img
                  src="/brochure/8954/images/encourageCallOut.png"
                  alt="Graphic that says: We encourage our members and associates to make a difference in their communities through patriotism and volunteerism."
                  width="400"
                  style={{ margin: '0' }}
                />
              </div>

              <p>
              We take the responsibility of corporate citizenship seriously. Giving back and making a difference wherever we can is what WoodmenLife is all about. As a not-for-profit life insurance company, we reinvest what would be profits to support local communities and serve those in need.
              </p>

              <div
                style={{
                  width: '100%',
                  margin: '0px 0px 20px 0px',
                }}
                className="centerText hideLg"
              >
                <img
                  src="/brochure/8954/images/encourageCallOut.png"
                  alt="Graphic that says: We encourage our members and associates to make a difference in their communities through patriotism and volunteerism."
                  className="img-responsive"
                />
              </div>

              <p>
              We encourage our members and associates to make a difference in their communities through patriotism and volunteerism. Wherever we do business, our associates and members have dedicated themselves to helping in times of need and honoring local and national heroes and volunteers. In 2023 alone, our community service activities totaled more than 109,000 volunteer hours. WoodmenLife and its members organized nearly 3,000 projects which included back-to-school supply drives, community cleanup and beautification projects, homeless shelter benefits, nurse appreciation events, Flag Day activities, First Responder appreciation events, and Veteran appreciation events.               
              </p>
              
              

                


                <div
                  style={{
                    float: 'left',
                    width: '170px',
                    margin: '10px 20px 20px 0px',
                    backgroundColor: '#ffffff',
                  }}
                  className="hideSm"
                  >
                  <img
                    src="/brochure/8954/images/garySinise.png"
                    alt="Gary Sinise Foundation graphic"
                    width="170"
                    style={{ margin: '0' }}
                  />
                </div>

                <div
                  style={{
                    float: 'left',
                    width: '140px',
                    margin: '10px 20px 20px 0px',
                    backgroundColor: '#ffffff',
                  }}
                  className="hideLg"
                  >
                  <img
                    src="/brochure/8954/images/garySinise.png"
                    alt="Gary Sinise Foundation graphic"
                    width="140"
                    style={{ margin: '0' }}
                  />
                </div>

              <p>
              As part of our dedication to celebrating America, we launched our patriotic program more than 70 years ago. Since 1947, we've donated more than 3.5 million flags to nonprofit organizations, schools and communities. WoodmenLife is the second-largest purchaser of American flags behind the United States government. In addition, WoodmenLife has partnered with the Gary Sinise Foundation as the official sponsor and sole provider of U.S. flags for the Remembrance Garden at the foundation's annual Snowball Express event. Our support helps further their mission of honoring our defenders, Veterans, First Responders, their families, and those in need.
              </p>

                
              
                


                <div
                  style={{
                    float: 'right',
                    width: '150px',
                    margin: '0px 0px 20px 20px',
                    backgroundColor: '#ffffff',
                  }}
                  className="hideSm"
                  >
                  <img
                    src="/brochure/8954/images/ncf.png"
                    alt="National Community Focus Fight Hunger graphic"
                    width="150"
                    style={{ margin: '0' }}
                  />
                </div>  

                <div
                  style={{
                    float: 'right',
                    width: '120px',
                    margin: '0px 0px 20px 20px',
                    backgroundColor: '#ffffff',
                  }}
                  className="hideLg"
                  >
                  <img
                    src="/brochure/8954/images/ncf.png"
                    alt="National Community Focus Fight Hunger graphic"
                    width="120"
                    style={{ margin: '0' }}
                  />
                </div>    
              
              
              
              <p>
                Our corporate citizenship doesn't end there. In 2015, WoodmenLife took action to fight hunger across America. Through our National Community Focus, WoodmenLife and our members have collected more than 1.3 million pounds of food and donated more than $3.6 million. Our efforts include community food drives, backpack stuffing events, sorting donations at local food banks, serving meals at community kitchens, and holding cooking fundraisers. WoodmenLife also fights hunger through our corporate giving.
                </p>


                

              <p>
              All these efforts fulfill our core value of serving others. We care deeply about family, community and country.
              </p>
              
          </div>
        </div>
      </div>


      <div className="container text-center">
        <div>

            <div className="size14" style={{ margin: '40px 0 0 0' }}>
              <b>In Partnership, We Succeed</b>
            </div>
            
            <div
              className="arrow bounce blueArrow"
              style={{ margin: '40px 0 150px 0' }}
            >
              <a
                className="fas fa-angle-down fa-3x nav-link js-scroll-trigger"
                href="#Connect"
              >
                <span className="sr-only">Connect</span>
              </a>
            </div>

        </div>
      </div>


    </section>
  );
};

export default HomeSection;
